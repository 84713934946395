import { reactive } from "vue";

const events = reactive({});

const off = (event, callback) => {
  if (events[event]) {
    const index = events[event].indexOf(callback);
    if (index > -1) {
      events[event].splice(index, 1);
    }
  }
};

const on = (event, callback) => {
  if (!events[event]) {
    events[event] = [];
  }
  events[event].push(callback);

  // Return an off function for convenience, so easily unregister this specific listener
  return () => {
    off(event, callback);
  };
};
const emit = (event, payload) => {
  if (events[event]) {
    for (const callback of events[event]) {
      callback(payload);
    }
  }
};

export const EventBus = {
  emit,
  on,
  off,
};
